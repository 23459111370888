
.App {
  background-color: rgb(50, 69, 78);
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
  text-align: center;
}

.center_text{
  
  text-align: center;

}